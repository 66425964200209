import React from 'react';
import './hero-block.less';
import HeroBottomImage from './hero-bottom.jpg';
import { HeroImageSchema } from './schema';
import isFunction from 'lodash/isFunction';
import { BlockDataForm, SidebarPortal } from '@plone/volto/components';
function View(props) {
  const { data = {}, block = null, onChangeBlock, selected } = props;
  const { title, description } = props.content;
  const schema = React.useMemo(() => {
    if (isFunction(HeroImageSchema)) {
      return HeroImageSchema(props);
    }
    return HeroImageSchema;
  }, [props]);
  return (
    <>
      <SidebarPortal selected={selected}>
        <BlockDataForm
          block={block}
          schema={schema}
          title={HeroImageSchema.title}
          onChangeBlock={props.onChangeBlock}
          onChangeField={(id, value) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          formData={data}
        />
      </SidebarPortal>
      <div
        className="hero-block"
        style={{
          backgroundColor:
            data?.styles?.backGroundColor === 'grey'
              ? '#F5F5F5'
              : 'transparent',
        }}
      >
        <div class="section-breaker bottom snipcss-Ke8Gn">
          <div
            class="section-breaker-img bottom"
            style={{
              backgroundImage: `url(${
                data?.hero_image
                  ? data?.hero_image + '/@@images/image/teaser'
                  : HeroBottomImage
              })`,
            }}
          ></div>
          <div class="section-breaker-body">
            <div class="title">
              <span>Thinking</span>
              <span class="q">Culture?</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default View;
