import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Icon } from 'semantic-ui-react';
import RichTextWidgetView from '@plone/volto-slate/widgets/RichTextWidgetView';
import { Icon as VoltoIcon } from '@plone/volto/components';
import rightSVG from '@plone/volto/icons/right-key.svg';
import downSVG from '@plone/volto/icons/down-key.svg';

import cx from 'classnames';

const AccordionTemplate = ({ items, descriptionKey }) => {
  const [activeIndices, setActiveIndices] = useState([]);
  const handleClick = (index) => {
    setActiveIndices((prevActiveIndices) =>
      prevActiveIndices.includes(index)
        ? prevActiveIndices.filter((i) => i !== index)
        : [...prevActiveIndices, index],
    );
  };

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <Accordion styled fluid>
        {items
          .sort((a, b) => (a?.title || '').localeCompare(b?.title || ''))
          .map((item, index) => (
            <React.Fragment key={index}>
              <Accordion.Title
                active={activeIndices.includes(index)}
                index={index}
                onClick={() => handleClick(index)}
                className={cx('accordion-title', {
                  'title-pending': item.review_state !== 'published',
                })}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>
                  <a href={item?.['@id']} className="title-accordion">
                    {item?.title}
                  </a>
                  {item?.degree_title && (
                    <>
                      <span
                        style={{
                          marginLeft: '5px',
                          marginRight: '5px',
                          fontSize: '20px',
                        }}
                      >
                        |
                      </span>
                      <span className="discreet">{item?.degree_title}</span>
                    </>
                  )}
                </div>

                <VoltoIcon
                  name={activeIndices.includes(index) ? rightSVG : downSVG}
                  style={{ width: '40px', height: '40px' }}
                  id={index}
                />
              </Accordion.Title>
              <Accordion.Content active={activeIndices.includes(index)}>
                <RichTextWidgetView
                  value={item[descriptionKey] || item?.institution_digest || ''}
                />
                <br />
                <a href={item?.['@id']} style={{ textDecoration: 'underline' }}>
                  Read more
                </a>
                {' >'}
              </Accordion.Content>
            </React.Fragment>
          ))}
      </Accordion>
    </div>
  );
};

export default AccordionTemplate;
