import { Plug } from '@plone/volto/components/manage/Pluggable';
import { applyPlausibleConfig } from 'volto-plausible';
import CarouselTemplate from './customizations/@kitconcept/volto-light-theme/components/Blocks/Listing/CarouselTemplate';
import EventsGrid from './customizations/@kitconcept/volto-light-theme/components/Blocks/Listing/EventsGrid';
import GridTemplate from './customizations/@kitconcept/volto-light-theme/components/Blocks/Listing/GridTemplate';
import { defaultStylingSchema } from '@kitconcept/volto-light-theme/components/Blocks/schema';
import { composeSchema } from '@plone/volto/helpers';
import { Editor, Transforms, Text } from 'slate';
import alignLeftIcon from '@plone/volto/icons/align-left.svg';
import alignRightIcon from '@plone/volto/icons/align-right.svg';
import alignCenterIcon from '@plone/volto/icons/align-center.svg';
import alignJustifyIcon from '@plone/volto/icons/align-justify.svg';
import { RouteCondition } from '@plone/volto/helpers/Slots';
import clearIcon from '@plone/volto/icons/ereaser.svg';
import Logo from './components/Logo/Logo';
import { Icon } from '@plone/volto/components';
import ProgramView from './components/Program/ProgramView';
import {
  MarkElementButton,
  ToolbarButton,
  BlockButton,
} from '@plone/volto-slate/editor/ui';
import StdkButtonEdit from './components/stdk-button/StdkButtonEdit.jsx';
import StdkButtonView from './components/stdk-button/StdkButtonView.jsx';
import NextCloudVideoEdit from './components/volto-hero-video/NextCloudVideoEdit';
import NextCloudVideoView from './components/volto-hero-video/NextCloudVideoView';
import TopSideFacets from '@plone/volto/components/manage/Blocks/Search/layout/TopSideFacets';
import LeftColumnFacets from '@plone/volto/components/manage/Blocks/Search/layout/LeftColumnFacets';
import RightColumnFacets from '@plone/volto/components/manage/Blocks/Search/layout/RightColumnFacets';
import ProgrammesFacets from './customizations/@kitconcept/volto-light-theme/components/Blocks/Search/components/ProgrammesFacets';
import AdvancedSearchProgrammes from './customizations/@kitconcept/volto-light-theme/components/Blocks/Search/components/AdvancedSearchProgrammes';
import View from './components/volto-hero-block/View.jsx';
import { searchBlockSchemaEnhancer } from './customizations/@kitconcept/volto-light-theme/components/Blocks/Search/components/schema';
import LayoutSchema from './components/volto-hero-video/LayoutSchema';
import StdkButtonSchema from './components/stdk-button/schema.js';
import { ProgrammeDates, ProgrammeDatesView } from './components/Widgets';
import ViewBottom from './components/volto-hero-block/ViewBottom.jsx';
import videoSVG from '@plone/volto/icons/videocamera.svg';
import stdkButtonSVG from '@plone/volto/icons/link.svg';
import CampusView from './components/Campus/CampusView';
import {
  defineMessages,
  createIntlCache,
  createIntl,
  FormattedMessage,
} from 'react-intl';
import TableTemplate from './customizations/@kitconcept/volto-light-theme/components/Blocks/Listing/TableTemplate';
import { HeroImageSchema } from './components/volto-hero-block/schema.js';
import InstitutionView from './components/Institution/InstitutionView';
import CarouselUniversities from './customizations/@kitconcept/volto-light-theme/components/Blocks/Listing/CarouselInstitution';
import AccordionTemplate from './customizations/@kitconcept/volto-light-theme/components/Blocks/Listing/AccordionTemplate';
import YouTubeVideoGrid from './customizations/@kitconcept/volto-light-theme/components/Blocks/Listing/WebVlogsListing';
import VlogsVideoGrid from './customizations/@kitconcept/volto-light-theme/components/Blocks/Listing/VlogsListing';
import { useSlate } from 'slate-react';
const isBlockClassActive = (editor, format) => {
  if (!editor.selection) return false;
  // TODO: someone fix this
  const levels = Array.from(Editor.levels(editor, editor.selection));
  if (levels.length < 2) return false;
  const [, [node]] = levels;
  return node.styleName === format;
};
const toggleBlockClassFormat = (editor, format) => {
  const levels = Array.from(Editor.levels(editor, editor.selection));
  // TODO: someone fix this
  if (levels.length < 2) return false;
  const [, [, path]] = levels;
  Transforms.setNodes(
    editor,
    { styleName: format },
    {
      at: path,
    },
  );
  return;
};

const clearFormatting = (editor) => {
  const sn = Array.from(
    Editor.nodes(editor, {
      mode: 'lowest',
      match: (n, p) => {
        return Text.isText(n);
      },
      //at: [0], // uncomment if you want everything to be cleared
    }),
  );

  sn.forEach(([n, at]) => {
    const toRemove = Object.keys(n).filter((k) => k.startsWith('style-'));
    if (toRemove.length) {
      Transforms.unsetNodes(editor, toRemove, { at });
    }
  });

  Transforms.setNodes(editor, {
    type: 'p',
    styleName: null,
  });
  Transforms.unwrapNodes(editor, {
    match: (n) => n.type && n.type !== 'p',
    mode: 'all',
    split: false,
  });
};
const ClearFormattingButton = ({ icon, ...props }) => {
  const editor = useSlate();

  const handleMouseDown = React.useCallback(
    (event) => {
      event.preventDefault();
      clearFormatting(editor);
    },
    [editor],
  );

  return <ToolbarButton {...props} onMouseDown={handleMouseDown} icon={icon} />;
};
function BlockClassButton({ format, icon, ...props }) {
  const editor = useSlate();

  const isActive = isBlockClassActive(editor, format);

  const handleMouseDown = React.useCallback(
    (event) => {
      event.preventDefault();
      toggleBlockClassFormat(editor, format);
    },
    [editor, format], // , isActive
  );

  return (
    <ToolbarButton
      {...props}
      active={isActive}
      onMouseDown={handleMouseDown}
      icon={icon}
    />
  );
}

const messages = defineMessages({
  NextCloudVideoTitle: {
    id: 'NextCloudVideo',
    defaultMessage: 'NextCloudVideo',
  },
});

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: 'en',
    messages: messages,
    formattedMessage: () => {},
  },
  cache,
);

const BG_COLORS = [{ name: 'transparent', label: 'Transparent' }];

const applyConfig = (config) => {
  config.blocks.requiredBlocks = [];
  config.settings.showTags = false;
  config.views.contentTypesViews.campus = CampusView;
  config.views.contentTypesViews.institution = InstitutionView;
  config.views.contentTypesViews.program = ProgramView;
  config.settings.navDepth = 5;
  config.blocks.blocksConfig.hero_block = {
    id: 'hero_block',
    view: View,
    edit: View,
    title: 'Hero Block',
    icon: videoSVG,
    group: 'common',
    schema: HeroImageSchema(),
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: false,
    sidebarTab: 1,
  };

  config.blocks.blocksConfig.hero_block_bottom = {
    id: 'hero_block_bottom',
    view: ViewBottom,
    edit: ViewBottom,
    title: 'Hero Bottom Block',
    icon: videoSVG,
    group: 'common',
    schema: HeroImageSchema(),
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: false,
    sidebarTab: 1,
  };

  config.blocks.blocksConfig.nextCloudVideoHero = {
    ...config.blocks.blocksConfig.nextCloudVideo,
    id: 'nextCloudVideoHero',
    title: 'Video Hero',
    icon: videoSVG,
    group: 'media',
    view: NextCloudVideoView,
    subtitlesLanguages: [['en', 'English']],
    edit: NextCloudVideoEdit,
    schema: LayoutSchema(intl),
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: false,
    sidebarTab: 1,
    whiteList: [
      'https://cmshare.eea.europa.eu',
      'https://shareit.eea.europa.eu',
    ],
    security: {
      addPermission: [],
      view: [],
    },
    autoAdd: false,
  };

  config.settings.integratesBlockStyles = [
    ...(config.settings.integratesBlockStyles || []),
    'nextCloudVideoHero',
  ];
  config.blocks.blocksConfig.stdkButton = {
    id: 'stdkButton',
    title: 'STDK Button',
    icon: stdkButtonSVG,
    group: 'common',
    view: StdkButtonView,
    edit: StdkButtonEdit,
    schema: StdkButtonSchema({ intl }),
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: false,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.leadimage.restricted = false;

  config.blocks.blocksConfig.listing = {
    ...config.blocks.blocksConfig.listing,
    schemaEnhancer: composeSchema(
      defaultStylingSchema,
      GridTemplate.schemaEnhancer,
    ),
    allowed_headline_tags: [['h2', 'h2']],
    variations: [
      ...config.blocks.blocksConfig.listing.variations,
      {
        id: 'carousel',
        title: 'Carousel',
        template: CarouselTemplate,
      },
      {
        id: 'events_grid',
        title: 'Events Grid',
        template: EventsGrid,
        schemaEnhancer: EventsGrid.schemaEnhancer,
      },
      {
        id: 'carousel_institution',
        title: 'Carousel Institution',
        template: CarouselUniversities,
      },
      {
        id: 'accordion',
        title: 'Accordion',
        template: AccordionTemplate,
      },
      {
        id: 'webisodes',
        title: 'WEBISODES',
        template: YouTubeVideoGrid,
      },
      {
        id: 'vlogs',
        title: 'VLOGS',
        template: VlogsVideoGrid,
      },
      {
        id: 'table_listing',
        title: 'Table',
        template: TableTemplate,
        schemaEnhancer: TableTemplate.schemaEnhancer,
      },
    ],
  };

  config.blocks.blocksConfig.search = {
    ...config.blocks.blocksConfig.search,
    schemaEnhancer: searchBlockSchemaEnhancer,
    variations: [
      {
        id: 'facetsTopSide',
        title: 'Facets on top',
        view: TopSideFacets,
        isDefault: true,
      },
      {
        id: 'facetsLeftSide',
        title: 'Facets on left',
        view: LeftColumnFacets,
        isDefault: false,
      },
      {
        id: 'facetsRightSide',
        title: 'Facets on right',
        view: RightColumnFacets,
        isDefault: false,
      },
      {
        id: 'facetsProgrammes',
        title: 'Facets Programmes',
        view: ProgrammesFacets,
        isDefault: false,
      },
      {
        id: 'advancedSearchPorgrammes',
        title: 'Advanced Search Programmes',
        view: AdvancedSearchProgrammes,
        isDefault: false,
      },
    ],
  };

  config.widgets.id.programme_dates = ProgrammeDates;
  config.widgets.widget.programme_dates = ProgrammeDates;

  if (config.widgets.views?.widget) {
    config.widgets.views.id.programme_dates = ProgrammeDatesView;
    config.widgets.views.widget.programme_dates = ProgrammeDatesView;
  }

  config.registerSlotComponent({
    slot: 'aboveContent',
    name: 'logo',
    component: () => (
      <div className="sitelogo-container">
        <Logo />
      </div>
    ),
    predicates: [RouteCondition('/([^/].*)', true)],
  });
  applyPlausibleConfig({ config, domain: 'stdk-plone6.edw.ro' });

  config.addonRoutes = [
    ...(config.addonRoutes || []).filter(
      (routes) => routes.path !== '/tfa-preferences',
    ),
  ];
  config.settings.nonContentRoutes = [
    ...(config.settings.nonContentRoutes || []).filter(
      (routes) => routes !== '/tfa-preferences',
    ),
  ];

  config.settings.appExtras = [
    ...config.settings.appExtras,
    {
      match: '',
      component: () => (
        <Plug pluggable="toolbar-user-menu" id="tfa-preferences">
          {() => {
            return null;
          }}
        </Plug>
      ),
    },
  ];

  // Text Align buttons

  // Align left
  if (!config.settings.slate.toolbarButtons.includes('text-left')) {
    config.settings.slate.buttons['text-left'] = (props) => (
      <BlockClassButton
        format="text-left"
        icon={alignLeftIcon}
        title="Align left"
        {...props}
      />
    );

    config.settings.slate.toolbarButtons = [
      ...config.settings.slate.toolbarButtons,
      'separator',
      'text-left',
    ];

    config.settings.slate.expandedToolbarButtons = [
      ...config.settings.slate.expandedToolbarButtons,
      'separator',
      'text-left',
    ];
  }

  // Align center
  if (!config.settings.slate.toolbarButtons.includes('text-center')) {
    config.settings.slate.buttons['text-center'] = (props) => (
      <BlockClassButton
        format="text-center"
        icon={alignCenterIcon}
        title="Align center"
        {...props}
      />
    );

    config.settings.slate.toolbarButtons = [
      ...config.settings.slate.toolbarButtons,
      'text-center',
    ];

    config.settings.slate.expandedToolbarButtons = [
      ...config.settings.slate.expandedToolbarButtons,
      'text-center',
    ];
  }

  // Align right
  if (!config.settings.slate.toolbarButtons.includes('text-right')) {
    config.settings.slate.buttons['text-right'] = (props) => (
      <BlockClassButton
        format="text-right"
        icon={alignRightIcon}
        title="Align right"
        {...props}
      />
    );

    config.settings.slate.toolbarButtons = [
      ...config.settings.slate.toolbarButtons,
      'text-right',
    ];

    config.settings.slate.expandedToolbarButtons = [
      ...config.settings.slate.expandedToolbarButtons,
      'text-right',
    ];
  }

  // Align justify
  if (!config.settings.slate.toolbarButtons.includes('text-justify')) {
    config.settings.slate.buttons['text-justify'] = (props) => (
      <BlockClassButton
        format="text-justify"
        icon={alignJustifyIcon}
        title="Align justify"
        {...props}
      />
    );

    config.settings.slate.toolbarButtons = [
      ...config.settings.slate.toolbarButtons,
      'text-justify',
      'separator',
    ];

    config.settings.slate.expandedToolbarButtons = [
      ...config.settings.slate.expandedToolbarButtons,
      'text-justify',
      'separator',
    ];
  }

  // Clear formatting
  config.settings.slate.buttons.clearformatting = (props) => (
    <ClearFormattingButton title="Clear formatting" icon={clearIcon} />
  );
  if (!config.settings.slate.toolbarButtons.includes('clearformatting')) {
    config.settings.slate.toolbarButtons = [
      ...config.settings.slate.toolbarButtons,
      'clearformatting',
    ];
    return config;
  }
  config.settings.navDepth = 4;
};

export default applyConfig;
