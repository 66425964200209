import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isInternalURL, flattenToAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import volumeOnSVG from '@plone/volto/icons/volume.svg';
import volumeOffSVG from '@plone/volto/icons/volume-off.svg';
import { Icon } from '@plone/volto/components';
const NextCloudEmbed = ({ data, embedSettings }) => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(data.autoPlay); // Inițializare bazată pe autoPlay
  const { branding } = data;
  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <>
      {/* Elementul Video */}
      <video
        ref={videoRef}
        src={
          isInternalURL(data.url)
            ? data.url.includes('@@download')
              ? flattenToAppURL(data.url)
              : `${flattenToAppURL(data.url)}/@@download/file`
            : `${data.url}/download`
        }
        autoPlay={data.autoPlay}
        muted={isMuted}
        loop={data.loop}
        poster={embedSettings.placeholder}
        type="video/mp4"
      >
        {data?.subtitles?.length > 0 &&
          data?.subtitles?.map((subtitle, index) => {
            if (subtitle?.file !== null && subtitle?.file !== undefined)
              return (
                <track
                  key={index}
                  label={
                    config?.blocks?.blocksConfig?.nextCloudVideo?.subtitlesLanguages.find(
                      (lang) => subtitle?.language === lang?.[0],
                    )?.[1]
                  }
                  kind="subtitles"
                  srcLang={subtitle?.language}
                  src={
                    typeof subtitle?.file === 'string' &&
                    isInternalURL(subtitle?.file)
                      ? flattenToAppURL(subtitle?.file) + '/@@download/file'
                      : `data:${subtitle?.file['content-type']};${subtitle?.file?.encoding},${subtitle?.file?.data}`
                  }
                />
              );
            else return <div key={index}></div>;
          })}
      </video>

      {/* Butonul pentru controlul sunetului */}
      {branding === true && (
        <button
          onClick={toggleMute}
          aria-label="Toggle sound"
          className="mute-button"
        >
          <Icon
            name={isMuted ? volumeOffSVG : volumeOnSVG}
            size="24px"
            color="#FFFFFF"
          />
        </button>
      )}
    </>
  );
};

NextCloudEmbed.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  embedSettings: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default NextCloudEmbed;
