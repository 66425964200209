import React from 'react';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
import Slider from 'react-slick';

const Arrow = () => {
  return (
    <svg
      width="48"
      height="25"
      xmlns="http://www.w3.org/2000/svg"
      style={{ vectorEffect: 'non-scaling-stroke' }}
    >
      <title>arrow-slider-icon</title>
      <g>
        <line
          stroke="#ffffff"
          y2="12.5"
          x2="46.5"
          y1="12.5"
          x1="0.5"
          strokeWidth="2"
        />
        <line
          y2="24.5"
          x2="34.5"
          y1="12.5"
          x1="46.5"
          strokeWidth="2"
          stroke="#ffffff"
        />
        <line
          transform="rotate(-90 40.5,6.5)"
          y2="12.5"
          x2="34.5"
          y1="0.5"
          x1="46.5"
          strokeWidth="2"
          stroke="#ffffff"
        />
      </g>
    </svg>
  );
};

const CustomNextArrow = ({ onClick }) => {
  return (
    <div
      className="custom-arrow next-arrow"
      onClick={onClick}
      style={{
        position: 'absolute',
        top: '45%',
        right: '10px',
        transform: 'translateY(-50%)',
        zIndex: 10,
        cursor: 'pointer',
      }}
    >
      <Arrow />
    </div>
  );
};

const CustomPrevArrow = ({ onClick }) => {
  return (
    <div
      className="custom-arrow prev-arrow"
      onClick={onClick}
      style={{
        position: 'absolute',
        top: '45%',
        left: '10px',
        transform: 'translateY(-50%) rotate(180deg)',
        zIndex: 10,
        cursor: 'pointer',
      }}
    >
      <Arrow />
    </div>
  );
};

const ImageGalleryTemplate = ({ items }) => {
  const imagesInfo = items
    .filter((item) => item.exclude_from_nav !== true)
    .map((item) => ({
      title: item.title,
      original: `${flattenToAppURL(item['@id'])}/@@images/${
        item.image_field
      }/large`,
      thumbnail: `${flattenToAppURL(item['@id'])}/@@images/${
        item.image_field
      }/thumb`,
      description: (
        <>
          <div className="title">{item.title}</div>
          <div className="description">{item.description}</div>
          <div className="download">
            <a
              href={`${flattenToAppURL(item['@id'])}/@@images/${
                item.image_field
              }/huge`}
              download
            >
              Download
            </a>
          </div>
          <div className="credits">{item.copyright_and_sources}</div>
        </>
      ),
    }));

  return (
    <div
      className="institution-slider"
      style={{
        padding: '20px',
        boxSizing: 'border-box',
        overflow: 'hidden',
        margin: 'auto',
        width: '95vw',
      }}
    >
      <Slider
        infinite
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        autoplay
        autoplaySpeed={2000}
        cssEase="linear"
        nextArrow={<CustomNextArrow />}
        prevArrow={<CustomPrevArrow />}
      >
        {imagesInfo.map((image, index) => (
          <div
            className="institution-slide-image"
            key={index}
            style={{
              margin: '0 auto',
              padding: '0 20px',
              width: '90vw',
            }}
          >
            <img
              src={image.original}
              alt={image.title}
              style={{
                borderRadius: '10px',
                width: '100%',
              }}
            />
            <div className="institution-slide-description">
              <div className="institution-slides-counter">
                <span className="institution-current-slide">{index + 1}</span>
                <span className="institution-separator"> / </span>
                <span className="institution-slides-number">
                  {imagesInfo.length}
                </span>
              </div>
              <p>{image.title}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

ImageGalleryTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ImageGalleryTemplate;
