import PropTypes from 'prop-types';
import { ConditionalLink, Component } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Grid } from 'semantic-ui-react';

import { isInternalURL } from '@plone/volto/helpers/Url/Url';

const GridTemplate = ({ items, linkTitle, linkHref, isEditMode, gridSize }) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkTitle || href}</a>;
  }
  return (
    <div className={`ui fluid ${gridSize || ''} cards`}>
      {/* <div className="grid items"> */}
      <div className="grid">
        <Grid stackable className="details-container">
          <Grid.Row columns={gridSize}>
            {items.map((item, idx) => (
              <Grid.Column className="grid-column listing-item" key={idx}>
                <a href={item.getURL || item['@id']}>
                  <div className="card-container">
                    <div className="image-container">
                      {item.image_field !== '' && (
                        <Component
                          componentName="PreviewImage"
                          item={item}
                          alt=""
                          className="item-image"
                        />
                      )}
                    </div>
                    <div className="item">
                      <div className="content">
                        {item?.head_title && (
                          <div className="headline">{item.head_title}</div>
                        )}

                        <h2>{item?.title}</h2>
                        {!item.hide_description && (
                          <p className="content-description">
                            {item?.description}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </a>
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </div>

      {link && <div className="footer">{link}</div>}
    </div>
  );
};

GridTemplate.schemaEnhancer = ({ schema }) => {
  schema.fieldsets.splice(1, 0, {
    id: 'description',
    title: 'Description',
    fields: ['description'],
  });
  schema.fieldsets.splice(2, 0, {
    id: 'cardsGrid',
    title: 'Grid',
    fields: ['gridSize'],
  });

  schema.properties = {
    ...schema.properties,
    gridSize: {
      title: 'Grid Size',
      choices: [
        ['two', 'Two'],
        ['three', 'Three'],
        ['four', 'Four'],
      ],
      default: 'two',
      factory: 'Choice',
      type: 'string',
    },
    description: {
      title: 'Description',
      widget: 'richtext',
    },
  };
  return schema;
};

GridTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default GridTemplate;
